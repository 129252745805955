<template>
  <div class="author-detail">
    <van-nav-bar fixed title="专家简介" left-text="返回" left-arrow @click-left="onClickLeft">
      <template #right>
        <div class="right-top" @click="goToAuthor">
          <p class="right-link">全部专家</p>
          <a-icon type="right"></a-icon>
        </div>
      </template>
    </van-nav-bar>

    <div class="con-wrap">
      <div class="top-wrap">
        <img :src="$utils.checkImg(author.avatar)" v-if="author.avatar" alt="" class="img" />
        <img v-else src="@/assets/female.png" alt="" class="img" />
        <div class="text-wrap">
          <div class="text-top-wrap">
            <p class="text-name">{{ author.pen_name }}</p>
          </div>
          <div class="text-center-wrap">
            <p class="text-center-group" v-if="author.external_group">{{ author.external_group }}</p>
          </div>
          <!-- <div class="text-bottom-wrap">
            <p class="text-bottom-post">{{ author.posts }}篇原创</p>
          </div> -->
        </div>
      </div>
      <!-- <div class="right-wrap">

        <order-tag :name="author.name"></order-tag>
      </div> -->
    </div>

    <van-tabs
      v-model="active"
      :border="true"
      offset-top="46"
      sticky
      swipeable
      scrollspy
      title-active-color="#4169e2"
      color="#4169e2"
      background="#f7f7f7"
    >
      <van-tab title="专家简介">
        <div class="des-wrap">
          <top-header title="专家简介"></top-header>
          <p class="des">{{ author.description || '暂无简介' }}</p>
        </div>
      </van-tab>

      <!-- <van-tab title="服务经验" v-if="practiceTab3.length">

      </van-tab> -->
      <van-tab title="团队介绍">
        <div class="group-wrap">
          <div class="top">
            <top-header title="团队介绍"></top-header>
            <p class="top-title">{{ author.external_group }}</p>
            <p class="top-label">{{ author.external_group_intro }}</p>
          </div>
          <div class="author-list-wrap">
            <top-header title="团队专家"></top-header>
            <flow-list url="home/author" :params="paramsGroup" :sep="false" v-if="paramsGroup.filters.external_group">
              <template slot-scope="row">
                <author-list-item :author="row.itemData" style="margin-bottom:16px"></author-list-item>
              </template>
            </flow-list>
          </div>
        </div>
      </van-tab>
      <van-tab title="专栏文章">
        <div class="list-wrap">
          <top-header title="专栏文章"></top-header>
          <flow-list url="home/authorPost" :params="paramsPost" :sep="false" @handleData="handleData">
            <template slot-scope="row">
              <expert-item :item="row.itemData"></expert-item>
            </template>
          </flow-list>
        </div>
      </van-tab>
    </van-tabs>
    <!-- <div class="live-chat">
      <a-button class="live-chat-btn" type="primary" @click="goToLiveChat">向TA咨询</a-button>
    </div> -->
  </div>
</template>

<script>
import TopHeader from '@/components/TopHeader';
import FlowList from '@/components/FlowList';
import AuthorListItem from '@/components/AuthorListItem';
import ExpertItem from './components/ExpertItem';
import { backInitMixin } from '@/utils/mixin';
import OrderTag from './components/OrderTag';
import { mapMutations } from 'vuex';
export default {
  name: 'AuthorDetail',
  components: {
    TopHeader,
    ExpertItem,
    FlowList,

    OrderTag,
    AuthorListItem
  },
  mixins: [backInitMixin],
  props: {},
  data() {
    return {
      author: {},
      active: 0,
      list: []
    };
  },
  computed: {
    paramsGroup() {
      return {
        limit_start: 0,
        limit_page_length: 10,
        filters: { external_group: this.author.external_group }
      };
    }
  },
  watch: {},
  created() {
    this.id = this.$route.params.id;
    this.params = {
      limit_start: 0,
      limit_page_length: 10,
      filters: { name: this.id }
    };
    this.paramsPost = {
      limit_start: 0,
      limit_page_length: 10,
      filters: { author_profile: this.id }
    };

    this._getData();
  },
  mounted() {},
  methods: {
    ...mapMutations({
      setAuthorData: 'SET_AUTHOR_DATA'
    }),
    goToAuthor() {
      this.$router.push('/author');
    },
    _getData() {
      this.$api['home/author'](this.params).then(res => {
        if (res.message) {
          this.setAuthorData(res.message[0]);
          this.author = res.message[0];
        }
      });
    },
    handleData(data) {
      this.list = data;
    },
    goToLiveChat() {
      this.$utils.goToLiveChat(this.author.pen_name);
    }
  }
};
</script>

<style scoped lang="less">
p {
  margin: 0;
}
.author-detail {
  padding-top: 46px;
  .con-wrap {
    padding: 0 16px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
  .des-wrap {
    padding: 0 16px;
    margin-top: 10px;
    .des {
      color: #333;
    }
  }
}
.group-wrap {
  padding: 16px 16px 0;
  p {
    color: #333;
    &.top-title {
      font-size: 16px;
      margin-bottom: 5px;
    }
  }
  .author-list-wrap {
    margin-top: 16px;
  }
}
.list-wrap {
  padding: 16px 16px 0;
}
.top-wrap {
  display: flex;
  flex-direction: row;
  position: relative;
  padding: 16px 0;
  img {
    width: 60px;
    height: 60px;
    border-radius: 30px;
  }
  .text-wrap {
    margin-left: 16px;
    display: flex;
    flex-direction: column;
    position: relative;
    justify-content: space-evenly;
    .text-top-wrap {
      display: flex;
      flex-direction: row;
      align-items: center;
      .text-name {
        font-size: 18px;
        font-weight: bold;
        color: @primary-color;
      }
      .text-line {
        margin: 0 10px;
      }
    }
    .text-center-wrap {
      margin-top: 5px;
      .text-center-group {
        font-size: 12px;
      }
    }
    .text-bottom-wrap {
      margin-top: 5px;
      .text-bottom-post {
        font-size: 12px;
      }
    }
  }
}
.live-chat {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;

  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;

  button {
    width: 200px;
    height: 40px;
    border-radius: 20px;
    box-shadow: 0px 0px 15px 0px @primary-color;
    font-weight: bold;
  }
}

.right-wrap {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}
.right-top {
  display: flex;
  flex-direction: row;
  align-items: center;

  p,
  i {
    color: @primary-color;
  }
  p {
    margin-right: 5px;
  }
  i {
    font-size: 12px;
  }
}
</style>
